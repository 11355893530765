import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "name", "output" ]

  connect() {
  }

  prev_card() {
    this.change_card(this.data.get("prevPath"))
  }

  next_card() {
    this.change_card(this.data.get("nextPath"))
  }

  private

  change_card(path) {
    console.log(path)
    if (path) {
      window.location = path
    } else {
      console.log("Path does not exist")
    }
  }

}
