/* global $ */
/* global localStorage */

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]


  initialize() {
    this.data.set("textMultiplierKey", "text-multiplier")
    this.data.set("defaultTextMultiplier", 150.0)
    this.data.set("maxTextMultiplier", 250.0)
    this.data.set("minTextMultiplier", 100.0)
    this.data.set("textMultiplierAdjustmnet", 10.0)
  }

  connect() {
    console.log('Font-size controller connected')

    this.initTextSize()
  }

  resetTextSize() {
    this.resizeText(this.fetchDataFloat("defaultTextMultiplier"))
  }

  increaseTextSize() {
    var multiplier = this.getTextMultiplier(this.fetchDataFloat("textMultiplierAdjustmnet"))
    multiplier += this.fetchDataFloat("textMultiplierAdjustmnet")

    var canIncrease = true

    if (multiplier >= this.fetchDataFloat("maxTextMultiplier")) {
      multiplier = this.fetchDataFloat("maxTextMultiplier")
      canIncrease = false
      $('.increase-text-size').addClass('disabled')
    }

    $('.decrease-text-size').removeClass('disabled')

    this.resizeText(multiplier)

    return canIncrease
  }

  decreaseTextSize() {
    var multiplier = this.getTextMultiplier(this.fetchDataFloat("textMultiplierAdjustmnet"))
    multiplier -= this.fetchDataFloat("textMultiplierAdjustmnet")

    var canDecrease = true
    if (multiplier <= this.fetchDataFloat("minTextMultiplier")) {
      multiplier = this.fetchDataFloat("minTextMultiplier")
      canDecrease = false
      $('.decrease-text-size').addClass('disabled')
    }
    $('.increase-text-size').removeClass('disabled')

    this.resizeText(multiplier)

    return canDecrease
  }

  private

  initTextSize() {
    var multiplier = this.getTextMultiplier()
    this.resizeText(multiplier)

    if (multiplier === this.fetchDataFloat("maxTextMultiplier")) {
      $('.increase-text-size').addClass('disabled')
    } else if (multiplier === this.fetchDataFloat("minTextMultiplier")) {
      $('.decrease-text-size').addClass('disabled')
    }
  }

  // https://davidwalsh.name/change-text-size-onclick-with-javascript
  resizeText(multiplier) {
    var fontSize = "" + multiplier + "%"
    document.body.style.fontSize = fontSize;
    $('textarea, .fontsized').css("font-size", "100%");

    this.localSave(this.data.get("textMultiplierKey"), multiplier)
    console.log("Text Scale Updated:" + multiplier)
  }

  getTextMultiplier() {
    var multiplier = this.localLoad(this.data.get("textMultiplierKey"))
    if (!multiplier) {
      multiplier = this.fetchDataFloat("defaultTextMultiplier")
    }

    return parseFloat(multiplier)
  }


  fetchDataFloat(key) {
    return parseFloat(this.data.get(key))
  }

  localSave(key,value) {
    return localStorage.setItem(key, value);
  }

  localLoad(key) {
    return localStorage.getItem(key);
  }
}





