/* global $ */
// @todo: The name on this is no longer accurate
import { Controller } from "stimulus"

export default class extends Controller {
  // There should only ever be one scroll container per controller definition.
  static targets = [ "scrollContainer" ]

  connect() {
    console.log('Scroll controller connected')
  }

  scroll(event) {
    event.preventDefault()

    var container = $(this.scrollContainerTarget);
    var scrollTarget = $(event.target).attr('data-scroll-to')
    var scrollTo = $('#' + scrollTarget);

    container.scrollTop(
        scrollTo.offset().top - container.offset().top + container.scrollTop()
    );

    // Or you can animate the scrolling:
    container.animate({
        scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()
    });
  }

}



